<template>
    <div>
      <div class="team">
        <div class="subtit d-inline-block text-h4 font-weight-bold">{{  $t('Page.Fnd.CoreTeam') }}</div>
        <v-row class="d-flex justify-start align-center">
        <!-- <v-col md="6"><v-card elevation="0" class="mt-15 px-4 px-md-10 py-8">
          <v-card height="400px" elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Page.Home.pricebsy') }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar2.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Page.Home.PriceDescbsy') }}</div>
            </div>
          </v-card>
        </v-card></v-col> -->
         <v-col md="6"><v-card elevation="0" class="mt-8 mt-md-15 px-1 px-md-10 py-8">
          <v-card height="600px" elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Page.Home.pricejtz') }}</div>
            <v-avatar class="mt-4 mt-md-10" size="150px">
              <img src="@/assets/images/avatar1.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4 mt-md-10">
              <div class="mt-2">{{ $t('Page.Home.PriceDescjtz') }}</div>
            </div>
          </v-card>
        </v-card></v-col>
        </v-row>

        <div class="mt-4 subtit d-inline-block text-h4 font-weight-bold">{{ $t('Page.Fnd.CoreTeam1') }}</div>
        <v-card  elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
              <div class="mt-2">{{  $t('Page.Fnd.loadingtext') }}</div>
          </v-card>

          <div class="mt-10 subtit d-inline-block text-h4 font-weight-bold">{{$t('Page.Fnd.CoreTeam2') }}</div>
        <v-card  elevation="0" color="#FBF9F6" class="mt-15 px-10 py-8 pr-6">
              <div class="mt-2">{{ $t('Page.Fnd.loadingtext') }}</div>
          </v-card>
      </div>
    </div>
</template>

<script>

export default {
  components: {
  },
  mounted(){
    this.newsil8n = this.$i18n.locale
  },
  data: () => ({
    newsil8n:'zh',
  }),
  methods: {
  },
  watch:{
    '$i18n.locale'(newil8n){
    this.newsil8n=newil8n;
    }
  },
}
</script>
  
<style lang="scss">
  .banner {
    position: relative;

    .content {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
      font-size: 18px;

      .tit {
        font-size: 56px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
      }
    }
  }

  .about {
    padding: 80px 120px;

    .subtit {
      position: relative;
    }

    .subtit::before {
      content: '';
      position: absolute;
      width: 60%;
      height: 7px;
      background: #ac8250;
      bottom: -20px;
      left: 0;
    }
  }

  @media screen and (max-width: 960px) {
    .banner {

      .content {
        font-size: 14px;

        .tit {
          font-size: 38px;
        }
      }
    }

    .about {
      padding: 30px 24px;
    }

    .twitter, .medium, .discord {
      width: 30px!important;
    }
  }
</style>